// Generated by Framer (38f2e7f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["dkXzXMcK_", "RykdFkysw"];

const serializationHash = "framer-TqC8c"

const variantClassNames = {dkXzXMcK_: "framer-v-exknzn", RykdFkysw: "framer-v-tcitbp"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {duration: 0, type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "dkXzXMcK_", "Variant 2": "RykdFkysw"}

const getProps = ({colorOff, colorOn, height, id, width, ...props}) => { return {...props, tV6RQgjy4: colorOn ?? props.tV6RQgjy4 ?? "rgb(133, 222, 255)", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "dkXzXMcK_", ZyC20jIX2: colorOff ?? props.ZyC20jIX2 ?? "rgba(133, 222, 255, 0.5)"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;colorOn?: string;colorOff?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, tV6RQgjy4, ZyC20jIX2, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "dkXzXMcK_", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear14ldrq9 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("RykdFkysw"), 1000)
})

const onAppeardknlfp = activeVariantCallback(async (...args) => {
await delay(() => setVariant("dkXzXMcK_"), 1000)
})

useOnVariantChange(baseVariant, {default: onAppear14ldrq9, RykdFkysw: onAppeardknlfp})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-exknzn", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"dkXzXMcK_"} ref={ref ?? ref1} style={{backgroundColor: tV6RQgjy4, borderBottomLeftRadius: 84, borderBottomRightRadius: 84, borderTopLeftRadius: 84, borderTopRightRadius: 84, boxShadow: "0px 1px 30px 0px var(--token-0d217399-5502-4e36-ad35-aff6664c8307, rgb(128, 255, 255))", ...style}} variants={{RykdFkysw: {backgroundColor: ZyC20jIX2, boxShadow: "none"}}} {...addPropertyOverrides({RykdFkysw: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-TqC8c.framer-14f8y1e, .framer-TqC8c .framer-14f8y1e { display: block; }", ".framer-TqC8c.framer-exknzn { height: 5px; overflow: hidden; position: relative; width: 5px; will-change: var(--framer-will-change-override, transform); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 5
 * @framerIntrinsicWidth 5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"RykdFkysw":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"tV6RQgjy4":"colorOn","ZyC20jIX2":"colorOff"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerbTxqQ5lQb: React.ComponentType<Props> = withCSS(Component, css, "framer-TqC8c") as typeof Component;
export default FramerbTxqQ5lQb;

FramerbTxqQ5lQb.displayName = "on/off";

FramerbTxqQ5lQb.defaultProps = {height: 5, width: 5};

addPropertyControls(FramerbTxqQ5lQb, {variant: {options: ["dkXzXMcK_", "RykdFkysw"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, tV6RQgjy4: {defaultValue: "rgb(133, 222, 255)", title: "color/on", type: ControlType.Color}, ZyC20jIX2: {defaultValue: "rgba(133, 222, 255, 0.5)", title: "color/off", type: ControlType.Color}} as any)

addFonts(FramerbTxqQ5lQb, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})